.technology-parth{
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
}

.paragraph-text{
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    color: #000;
    font-family: "HyundaiRegular";
    max-width: 800px;
    margin: auto;
}

.technology-header{
    margin-bottom: 20px;
    font-size: 44px;
    letter-spacing: 0.7px;
    font-family: "HyundaiMedium";
    line-height: 54px;
    font-weight: normal;
}

.technology-sec-part{
    background-color: #f6f3f2 ;
}
.page-main-img{
    width: 100%;
}