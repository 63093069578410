.technology-header-ioniq{
    font-size: 24px;
    padding-bottom: 50px;
    padding: 50px;
}

.technology-headerioniq{
    font-size: 44px;
    padding-bottom: 50px;
    padding: 50px;
}

.ioniq-par{
    width: 50%;
    margin: 0 auto;
    padding-bottom: 50px;
}

.grid-area {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    background-color: #f6f3f2;
}

.grid-area-tec{
    display: flex;
    justify-content: space-between;
    padding: 50px;
}

.tec-sec{
    background-color: #f6f3f2;
}
.tec-sec h1, .tec-thir h1{
    text-align: center;
    padding: 50px;
}
.ioniq-tec-part{
    background-color: #f6f3f2; 
}

.text-area {
    max-width: 50%;
}

.text-area h3 {
    font-size: 30px;
    font-weight: 700;
}

.text-area p {
    color: #000;
    font-size: 16px;
    line-height: 28px;
}

.grid-area-sec{
    display: flex;
    justify-content: space-between;
    padding: 50px;
}

.charging-img{
    padding: 50px;
}