.footer-wrap{
    padding-bottom: 24px;
    background: rgb(28, 27, 27);
    padding-top: 24px;
}

.footer-cont{
    display: flex;
    justify-content: space-around;
}

.footer-menu{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
}

.footer-cont li {
    list-style: none;
    padding: 0 6px;
    line-height: 1;
}

.footer-link-list{
    color: #999;
    font-size: 14px;

}

.footer-cont{
    color: #fff;
}

