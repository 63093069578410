.second-navbar{
    position: sticky;
    top: 0;
    background-color: #fff;
}

.second-parth-tucson{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 450px;
    width: 100%;
    background-image: url("https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/interior/tucson-nx4-fmc-design-interior-accordion1-pc.jpg");
}

.second-parth-tucson{

}

.exterier-header{
    font-size: 30px;
    color: #fff;
    font-family: "HyundaiMedium";
    line-height: 40px;
    letter-spacing: 0.5px;
    display: inline-block;
    vertical-align: middle;
}

.second-parth-tucson p{
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    color: #fff;
    font-family: "HyundaiRegular";
}

.first-parth-sonata{
    text-align: center;
    padding-bottom: 40px;
}

.tit-wrap{
    margin-top: 50px;
}

.tit-wrap h1{
    font-size: 44px;
    font-weight: 600;
}

.tit-wrap p {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
}

.tit-wrap-header{
    padding-bottom: 20px;
}

.max-comf-header{
    font-size: 44px;
    line-height: 54px;
    padding-top: 30px;
}

.max-comf{
    margin-top: 50px;
 background-color: #f6f3f2;
 padding-bottom: 40px;
}

.display-part{
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.5px;
    color: #000;
    margin-top: 40px;
    padding-bottom: 25px;
}


/* Ext part design */

.ext-div{
    padding-bottom: 40px;
}

.ext-first {
    background-color: #f6f3f2;
    display: flex;
    justify-content: center;
    height: 300px;
    text-align: center;
    align-items: center;
}

.front-view{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/exterior/sonata-dn8-design-front-accordion-pc.jpg");
}

.front-view h2{
    font-size: 44px;
    color: #fff;
}

.front-view p{
    font-size: 16px;
    color: #fff;
}

.enlarge{
    overflow: hidden;
    width: 50px;
    height: 50px;
    background: #00aad2;
    color: #fff;
    font-size: 30px;
    border-radius: 50%;
    border: none;

  }

  .front-vew-component{
    text-align: center;
  }

  .front-vew-component h3 {
    margin-top: 30px;
    font-size: 44px;
    line-height: 54px;
    padding-bottom: 25px;
  }


  .radiator-parth{
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
  }

  .radiator-parth p {
    font-size: 30px;
    font-weight: 500;
    margin-top: 20px;
  }


  .ligth-part{
    padding-top: 0px;
    display: flex;
    justify-content: space-around;
  }

  .ligth-part img {
    width: 400px;
  }

  .ligth-part p{
    max-width: 400px;
    margin-top: 20px;
  }

  
  /* Conveince */

  .convenience-div{
    text-align: center;
  }


  .convenience-head{
    margin-top: 40px;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 0.7px;
    color: #000;
    font-weight: 500;
    padding-bottom: 20px;
  }

  .convenience-par {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    padding-bottom: 30px;
    padding: 45px;
  }


  .module-parth{
    display: flex;
    justify-content: space-around;
  }

  .module-parth p{
    max-width: 600px;
    font-size: 16px;
    font-weight: 400;
  }

  .module-parth div{
    text-align: start;
  }

  .module-parth h3 {
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
  }

  .tucson-interier-parth{
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
  }


  .tucson-interier-parth img, .tucson-interier-parth p {
    max-width: 540px;
  }
  .tucson-interier-parth p {
    margin-top: 25px;
    font-size: 30px;
    font-weight: 500;
  }


  .tucson-conv-parth{
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
  }

  .tucson-conv-parth img, .tucson-conv-parth h3{
    max-width: 540px;
  }

  .tucson-conv-parth h3 {
    margin-top: 25px;
    font-size: 30px;
    font-weight: 500;
  }