.hystory-parth{
    padding-bottom: 40px;
}
.hystory-header{
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.5px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.hystory-paragraph{
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
}

.history-header-sec{
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-bottom: 40px;
    font-weight: bold;
}