.second-navbar {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.second-parth-santafe {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 450px;
  width: 100%;
  background-image: url("https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/santa-fe-2021/interior/santa-fe-tm-fl-design-interior-accordion-pc.jpg");
}

.exterier-header {
  font-size: 30px;
  color: #fff;
  font-family: "HyundaiMedium";
  line-height: 40px;
  letter-spacing: 0.5px;
  display: inline-block;
  vertical-align: middle;
}

.second-parth-sonata p {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #fff;
  font-family: "HyundaiRegular";
}

.first-parth-sonata {
  text-align: center;
  padding-bottom: 40px;
}

.tit-wrap {
  margin-top: 50px;
}

.tit-wrap h1 {
  font-size: 44px;
  font-weight: 600;
}

.tit-wrap p {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
}

.tit-wrap-header {
  padding-bottom: 20px;
}

.max-comf-header {
  font-size: 44px;
  line-height: 54px;
  padding-top: 30px;
}

.max-comf {
  margin-top: 50px;
  background-color: #f6f3f2;
  padding-bottom: 40px;
}

.display-part {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: #000;
  margin-top: 40px;
  padding-bottom: 25px;
}

/* Ext part design */

.ext-div {
  padding-bottom: 40px;
}

.ext-first {
  background-color: #f6f3f2;
  display: flex;
  justify-content: center;
  height: 300px;
  text-align: center;
  align-items: center;
}

.front-view-santafe {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/santa-fe-2021/exterior/santa-fe-tm-fl-design-front-accordion-pc.jpg");
}

.side-view-santafe {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/santa-fe-2021/exterior/santa-fe-tm-fl-design-side-accordion-pc.jpg");
}

.back-view-santafe {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/santa-fe-2021/exterior/santa-fe-tm-fl-design-rear-accordion-pc.jpg");
}

.front-view-santafe h2,
.side-view-santafe h2,
.back-view-santafe h2 {
  font-size: 44px;
  color: #fff;
}

.front-view-santafe p,
.side-view-santafe p,
.back-view-santafe p {
  font-size: 16px;
  color: #fff;
}

.enlarge {
  overflow: hidden;
  width: 50px;
  height: 50px;
  background: #00aad2;
  color: #fff;
  font-size: 30px;
  border-radius: 50%;
  border: none;
}

.front-vew-component {
  text-align: center;
}

.front-vew-component h3 {
  margin-top: 30px;
  font-size: 44px;
  line-height: 54px;
  padding-bottom: 25px;
}

.radiator-parth {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}

.radiator-parth p {
  font-size: 30px;
  font-weight: 500;
  margin-top: 20px;
}

.ligth-part-santafe {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}

.ligth-part img {
  width: 400px;
}

.ligth-part p {
  max-width: 400px;
  margin-top: 20px;
}

/* Conveince */

.convenience-div {
  text-align: center;
}

.convenience-head {
  margin-top: 40px;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 0.7px;
  color: #000;
  font-weight: 500;
  padding-bottom: 20px;
}

.convenience-par {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  padding-bottom: 30px;
  padding: 45px;
}

.module-parth {
  display: flex;
  justify-content: space-around;
}

.module-parth p {
  max-width: 600px;
  font-size: 16px;
  font-weight: 400;
}

.module-parth div {
  text-align: start;
}

.module-parth h3 {
  font-size: 20px;
  font-weight: 800;
  margin-top: 20px;
}

.ligth-part-santafe img,
.ligth-part-santafe p {
  max-width: 350px;
}

.notify-text {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.notify-text p {
  max-width: 1120px;
}

.santafe-conf-zone {
  display: flex;
  justify-content: space-around;
  padding-bottom: 50px;
  padding-top: 30px;
}

.santafe-conf-zone img {
  max-width: 350px;
}

.proection-div {
  background-color: #f6f3f2;
}

.proection-sec {
  padding-top: 50px;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.proection-sec p {
  font-size: 30px;
  font-weight: 500;
  margin-top: 25px;
  max-width: 500px;
}

.santafe-main-conf-sec {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}

.santafe-main-conf-sec p {
  max-width: 350px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 25px;
}

.santafe-main-conf-sec img {
  max-width: 350px;
}

.section-media {
  text-align: center;
  padding-bottom: 50px;
}

.section-media-sec {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 50px;
}

.section-media-sec h3 {
  font-size: 44px;
  line-height: 54px;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.section-media-sec h4 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
}

.sect-med-p {
  font-family: "HyundaiTextRegular";
  line-height: 28px;
  font-weight: 400;
}
.conditioner-sec {
  width: 50%;
}

.wirless-chargeng, .digital-hiligt {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  background-color: #f6f3f2;
}

.wirless-chargeng h2, .full-access-arr h2 {
  font-size: 44px;
  line-height: 54px;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: -0.01em;
}

.wirless-chargeng p, .full-access-arr p {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 26px;
  letter-spacing: -0.02em;
}

.full-access-arr{
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
 .fing-sec{
  padding: 50px;
  display: flex;
  justify-content: space-between;
 }

 .engine-parth-santafe{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px;
  height: 700px;
  background-position: top;
  align-items: end;
  background-repeat: no-repeat;
  background-image: url("https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/performance/santafe-2023-performance-driving-on-road-pc.jpg")
 }

 .unleash{
  padding-top: 30px;
  text-align: center;
 }

 .unleash h2 {
  font-weight: 700;
  font-size: 30px;
  padding-bottom: 30px;
  padding-top: 30px;
 }