.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: auto;
    height: 500px;
    background-repeat: no-repeat;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

  .slider-par{
    color: #fff;
    font-family: HyundaiRegular;
    font-size: 58px;
    left: 150px;
    line-height: 64px;
    position: absolute;
    top: 100px;
  }

  .each-slide-effect{
    position: relative;
  }