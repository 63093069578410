.App .swiper-hyundai{
    padding-bottom:40px;
}

.swiper-div{
    text-align: center;
}

.models-par{
    padding-bottom: 100px;
    padding-top: 100px;
    text-align: center;
    font-size: 44px;
    font-family: "HyundaiMedium";
    line-height: 54px;
letter-spacing: 0.88px;}

.swiper-models{
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    font-weight: 700;
}


.r-card {
    gap: 0.6rem;
    padding: 1rem;
    border-radius: 10px;
    max-width: max-content;
    margin: auto;
    transition: all 300ms ease-in;
  }
  .slider-img {
    width: 450px;
    
  }